import {useMemo} from 'react';
import {useAppState} from "../context/AppContext";
import {Section} from "../types/Section";
import {Block} from "../types/Block";

type SectionData = {
  section?:Section,
  childSections?: Section[],
  blocks?: Block[],
  stage?: Block[],
  showFooter?:boolean,
}

const useSection = (pathname:string, data?: Section) => {
  const {listedSections} = useAppState()

  const myData:SectionData = useMemo(() => {
    const newData:SectionData = {};
    if(listedSections) {
      const mySection = data ?? listedSections?.find(s => s.url === pathname)
      if(mySection) {
        newData.section = mySection
        newData.showFooter = mySection.showfooter
        if(mySection.id === "default") {
          if(mySection.sections) {
            newData.childSections = mySection.sections
          }
        }
        if(mySection.stage) {
          newData.stage = mySection.stage
        }
        if(mySection.blocks) {
          newData.blocks = mySection.blocks
        }
      }
    }
    return newData
  }, [listedSections, data]);

  return myData
};

export default useSection;