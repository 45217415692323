import React, {PropsWithChildren} from 'react'
import {Box} from "@mui/material";
import {SxProps, Theme} from "@mui/system";
import {BlockImageExt, Breakpoints} from "../../types/Block";
import {IKirbyImage} from "../../types/IKirbyImage";
import {cssWrapper, getResponsiveUnit} from "../../utils/utils";

interface Props extends PropsWithChildren<any>{
  data:BlockImageExt,
  sx?:SxProps<Theme>,
  scope?: string | undefined,
}

type Responsive = {
  xs:string|undefined,
  sm?:string|undefined,
  md?:string|undefined,
  lg?:string|undefined,
  xl?:string|undefined
}

export default function KirbyImage (props:Props) {
  const {responsiveimage, src, srcxs, alt, position, sizes, ratios} = props.data;

  function bi (img:IKirbyImage):string|undefined {
    if(img && img.url){
      return `url('${img.url}')`;
    }
  }

  // console.log('Image - responsiveimage: ', responsiveimage);
  // console.log('Image - sizes: ', sizes);
  // console.log(alt, 'ratios: ', ratios);
  // console.log('Image - position: ', position);
  // console.log('Image - ratio: ', ratio);
  // console.log('Image - getImageSrc: ', getImageSrc());

  function getImageSrc():Responsive | string | undefined {
    if(responsiveimage && responsiveimage.xs) {
      return {
        xs: bi(responsiveimage.xs),
        sm: bi(responsiveimage.sm),
        md: bi(responsiveimage.md),
        lg: bi(responsiveimage.lg),
        xl: bi(responsiveimage.xl),
      };
    } else if (src) {
      return {
        xs: `url('${srcxs ?? src}')`,
        md: `url('${src}')`,
      };
    }
    return undefined
  }

  function parseSize ():Responsive {
    // console.log('parseSize - parseSize: ', sizes)
    const values:Responsive = {
      xs: "cover",
    };
    if (sizes && sizes.xs) {
      if(sizes.xs) values.xs = sizes.xs;
      if(sizes.sm) values.sm = sizes.sm;
      if(sizes.md) values.md = sizes.md;
      if(sizes.lg) values.lg = sizes.lg;
      if(sizes.xl) values.xl = sizes.xl;
      return values;
    }
    return values;
  }

  function isValid (bp:Breakpoints):boolean {
    let valid = false;
    if (ratios && ratios.length > 0) {
      ratios.forEach((v) => {
        if(!valid) valid = (v.breakpoint && v.breakpoint.size && v.breakpoint.size === bp)
      })
    }
    return valid;
  }

  function getHeight(bp:Breakpoints):string | undefined {
    let h = undefined;
    if (ratios && ratios.length > 0) {
      ratios.forEach((v) => {
        if (v.breakpoint && v.breakpoint.size && v.breakpoint.size === bp) {
          // console.log(alt, 'found bp getHeight - : ',bp, v.breakpoint)
          if(v.breakpoint.ratio === 'fixed') {
            h = `${v.breakpoint.height}${getResponsiveUnit(v.breakpoint.unit)}`;
          } else if (v.breakpoint.ratio) {
            h = "0";
          }
        }
      })
    }
    return h;
  }

  function getPaddingBottom(bp:Breakpoints):string | undefined {
    let padding = "unset";
    if (ratios && ratios.length > 0) {
      ratios.forEach((v) => {
        if (v.breakpoint && v.breakpoint.size && v.breakpoint.size === bp) {
          if(v.breakpoint.ratio === '1_2') {
            padding = "50%";
          } else if (v.breakpoint.ratio === "4_3") {
            padding = "75%";
          } else if (v.breakpoint.ratio === "1_1") {
            padding = "100%";
          }
        }
      })
    }
    return padding;
  }

  function parseRatios ():SxProps<Theme> | undefined {
    const sx:SxProps<Theme> = {
      height: {
        xs: getHeight("xs") ?? "100%",
        ...(isValid("sm") && { sm: getHeight("sm")}),
        ...(isValid("md") && { md: getHeight("md")}),
        ...(isValid("lg") && { lg: getHeight("lg")}),
        ...(isValid("xl") && { xl: getHeight("xl")}),
      },
      paddingBottom: {
        xs: getPaddingBottom("xs") ?? "0",
        ...(isValid("sm") && { sm: getPaddingBottom("sm")}),
        ...(isValid("md") && { md: getPaddingBottom("md")}),
        ...(isValid("lg") && { lg: getPaddingBottom("lg")}),
        ...(isValid("xl") && { xl: getPaddingBottom("xl")}),
      },
    }
    // console.log(alt, 'parseRatios - parseRatios: ', sx);
    return sx;
  }

  function parsePosition ():Responsive | string {
    if (position && position.length > 0) {
      const values:Responsive = {
        xs: "center",
      }
      position.forEach((v) => {
        if (v.breakpoint && v.breakpoint.size) {
         values[v.breakpoint.size] = `${v.breakpoint.vertical} ${v.breakpoint.horizontal}`
        }
      })
      return values;
    }
    return "center";
  }

  function getImageElement():JSX.Element {
    if(responsiveimage && responsiveimage.xs) {
      return (
        <Box
          className={'image'}
          aria-label={alt}
          sx={{
            backgroundImage: getImageSrc(),
            backgroundSize: parseSize(),
            backgroundRepeat: "no-repeat",
            backgroundPosition: parsePosition(),
            width: "100%",
            maxWidth: "100vw",
            overflowX: "hidden",
            ...parseRatios()
          }}
        >
          {props.children}
        </Box>
      );
    }
    return (
      <Box
        className={'image'}
        sx={{
          width: "100%",
        }}
      >
        <img src={src} alt={alt} width={"100%"} />
      </Box>
    );
  }

  return cssWrapper(getImageElement(), props.data.css);
}

