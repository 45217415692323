import React, {PropsWithChildren} from 'react'
import {Stack} from "@mui/material";
import {Block, BlockPanel} from "../../types/Block";
import {cssWrapper} from "../../utils/utils";
import {useTheme} from "@mui/system";

interface Props extends PropsWithChildren<any>{
  data:BlockPanel,
  getBlockElement?(b:Block, idx:number, scope?:string):JSX.Element | null,
}

export default function KirbyPanel (props:Props) {
  const { variant, blocks, id } = props.data;
  const theme = useTheme();

  function getBackgroundColor():string {
    switch (variant) {
      case "secondary":
        return theme.palette.color.secondary.light;
      case "accent":
        return theme.palette.color.accent.main;
      case "primary":
      default:
        return theme.palette.color.white;
    }
  }

  return cssWrapper(
    <Stack
      direction={"column"}
      id={id}
      sx={{
        width: "100%",
        alignContent: "flex-start",
        px: 4,
        backgroundColor: getBackgroundColor(),
      }}
    >
      {props.getBlockElement && blocks && blocks?.map((b:Block, idx) => {
        if(props.getBlockElement) {
          return props.getBlockElement(
            b, idx, "panel"
          )
        }
        return null;
      })}
    </Stack>
    , props.data.css
  )
}
