import React, {ReactNode, useEffect, useMemo, useState} from 'react'
import {Link as RouterLink, useLocation} from 'react-router-dom';
import useSection from "../../hooks/useSection";
import {Section} from '../../types/Section';
import {Box, Button, Stack} from "@mui/material";
import {KirbyProps} from "../../types/KirbyProps";
import {SxProps} from "@mui/system";
import KirbyFooter from "../../components/KirbyFooter/KirbyFooter";
import {Block, BlockType} from "../../types/Block";
import {useAppState} from 'src/context/AppContext';

interface Props extends KirbyProps {
  data?:Section,
  inline?:boolean,
  parentUrl?:string,
  getBlockElement (b:Block, idx:number, scope?:string):JSX.Element | null,
  getFooter?():JSX.Element | null,
  wrapWithRow (element:ReactNode | null, type?:BlockType | "", idx?:number):JSX.Element | ReactNode | null
}

function getSection (id:string | undefined,children:ReactNode, sx?:SxProps<any> | undefined, inline?:boolean) {
  return (
    <Box
      component={"section"}
      display={"flex"} flexDirection={"column"}
      sx={{
        padding: "0",
        minHeight: inline ? "unset" : "calc( 100vh - 4rem )",
        ...sx
      }}
      id={id}
    >
      {children}
    </Box>
  )
}

export default function KirbySection (props:Props) {
  const { pathname } = useLocation();
  const { blocks } = useSection(pathname.substring(1), props.data);
  const appState = useAppState();
  const {getBlockElement, wrapWithRow} = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function onResize() {
    setWindowWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const myChildren = useMemo(() => {
    return wrapWithRow(
      blocks?.map((block, idx) => {
        const component = getBlockElement(block, idx);
        return component;
        // return wrapWithRow(component, block.type, idx+1);
      })
      , BlockType.PAGE) ?? null;
  }, [blocks, getBlockElement, wrapWithRow, windowWidth]);

  const myFooter = useMemo(() => {
    if(props.data?.showfooter) {
      if(props.getFooter) return props.getFooter()
      return <KirbyFooter data={appState.footer} />
    }
    return null
  }, [props.data?.showfooter, appState.footer, props.getFooter, windowWidth]);

  const mySection = useMemo(() => {
    if(
      props.data?.id === "default"
    ) {
      return getSection(
        props.data?.url ?? undefined,
        <>
          {props.parentUrl &&
          wrapWithRow(
            <Stack mt={16} alignSelf={"flex-end"} justifyContent={"flex-end"}>
              <Button variant={"contained"} component={RouterLink} to={`/${props.parentUrl}`}>Zurück</Button>
            </Stack>
          )
          }
          { myChildren }
          <Box sx={{flexGrow: 1}}/>
          {myFooter}
        </>,
        props.sx,
        props.inline
      )
    }
    return null
  }, [props.data?.id, props.data?.url, props.parentUrl, props.sx, props.inline, windowWidth]);

  return mySection ?? <Box>`Template is not defined`</Box>
}