import {BlockSpacer} from "../../types/Block";
import React from "react";
import {Box} from "@mui/material";
import {SxProps, Theme} from "@mui/system";
import {KirbyProps} from "src/types/KirbyProps";

interface Props extends KirbyProps {
  data:BlockSpacer,
  scope?: string | undefined,
}

export default function KirbySpacer(props:Props):JSX.Element {

  const {height, minheight, maxheight} = props.data;

  const sxData:SxProps<Theme> = {
    height: {
      xs: `${height.xs}${height.unit}`,
      ...(height.sm && {sm: `${height.sm}${height.unit}`}),
      ...(height.md && {md: `${height.md}${height.unit}`}),
      ...(height.lg && {lg: `${height.lg}${height.unit}`}),
      ...(height.xl && {xl: `${height.xl}${height.unit}`}),
    },
    minHeight: {
      xs: `${minheight.xs}${minheight.unit}`,
      ...(minheight.sm && {sm: `${minheight.sm}${minheight.unit}`}),
      ...(minheight.md && {md: `${minheight.md}${minheight.unit}`}),
      ...(minheight.lg && {lg: `${minheight.lg}${minheight.unit}`}),
      ...(minheight.xl && {xl: `${minheight.xl}${minheight.unit}`}),
    },
    maxHeight: {
      xs: `${maxheight.xs}${maxheight.unit}`,
      ...(maxheight.sm && {sm: `${maxheight.sm}${maxheight.unit}`}),
      ...(maxheight.md && {md: `${maxheight.md}${maxheight.unit}`}),
      ...(maxheight.lg && {lg: `${maxheight.lg}${maxheight.unit}`}),
      ...(maxheight.xl && {xl: `${maxheight.xl}${maxheight.unit}`}),
    },
  };

  return (
    <Box
      className={"spacer"}
      sx={{
        ...sxData
      }}
    />
  );
}