import React, {ReactNode, useCallback, useMemo} from 'react'
import {Routes, Route, Navigate} from "react-router-dom";
import {Section} from "../../types/Section";
import {KirbyProps} from "../../types/KirbyProps";

interface Props extends KirbyProps {
  sections: Section[],
  getSectionElement(section:Section, parentUrl:string, inline:boolean):JSX.Element,
}

export default function KirbyRouter (props:Props) {

  const {sections, getSectionElement} = props

  function _getRoutes (sections:Section[], addRedirect:boolean = false, parentUrl:string = "") {
    const routes:any = []
    // console.log('getRoutes - sections: ', sections)
    if(sections && sections.length > 0) {
      sections.forEach((section: Section) => {
        // console.log('getRoute - section: ', section.url)
        // push potential sub routes
        const subRoutes:ReactNode[] = section.id === 'default' ? getRoutes(section.sections, false, section.url) : []
        if (subRoutes && subRoutes.length > 0) {
          subRoutes.forEach((sr:any) => routes.push(sr))
        }
        // console.log('default - subRoutes: ', section.url, ":", subRoutes)
        // push main route
        if(section.url) {
          routes.push(
            <Route
              key={section.url}
              path={`/${section.url}`}
              element={getSectionElement(section, parentUrl, false)}
            />
          )
        }
      })
    }
    if(sections[0]?.url && addRedirect) {
      routes.push(<Route key={"redirect"} path="*" element={<Navigate replace to={sections[0].url} />} />)
    }
    return routes
  }

  const getRoutes = useCallback(_getRoutes, [sections, _getRoutes, getSectionElement])

  /* eslint-disable-next-line */
  const kirbyRoutes = useMemo(() => getRoutes(sections, true), [sections]);

  // console.log('default - KirbyRouter: ', props.sections)
  return (
    <Routes>
      {sections && kirbyRoutes}
    </Routes>
  )
}
