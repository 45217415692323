import {Block, BlockType} from "../types/Block";
import KirbyHeading from "../components/KirbyHeading/KirbyHeading";
import KirbyText from "../components/KirbyText/KirbyText";
import KirbyAnimations from "../components/KirbyAnimations/KirbyAnimations";
import KirbyAnimation from "../components/KirbyAnimation/KirbyAnimation";
import KirbyCardGroup from "../components/KirbyCardGroup/KirbyCardGroup";
import KirbyGallery from "../components/KirbyGallery/KirbyGallery";
import KirbyImage from "../components/KirbyImage/KirbyImage";
import KirbyFooter from "../components/KirbyFooter/KirbyFooter";
import KirbyList from "../components/KirbyList/KirbyList";
import KirbyPanel from "../components/KirbyPanel/KirbyPanel";
import KirbyAnchorNavigation from "../components/KirbyAnchorNavigation/KirbyAnchorNavigation";
import KirbyFrame from "../components/KirbyFrame/KirbyFrame";
import KirbySpacer from "../components/KirbySpacer/KirbySpacer";
import React, {ReactNode} from "react";
import {Container, Grid} from "@mui/material";

export function wrapWithRow (element:ReactNode, type?:BlockType | "", idx?:number) {
  const key = idx || type ? `${type ?? "block"}-${idx ? idx.toString() : "0"}` : ""

  switch (type) {
    case BlockType.ANIMATIONS:
    case BlockType.ANIMATION:
    case BlockType.SLIDER:
    case BlockType.PAGECONTAINER:
    case BlockType.FRAME:
      return (
        <Container maxWidth={"xl"} key={key}>
          <Grid container>
            <Grid item xs={12}>
              {element}
            </Grid>
          </Grid>
        </Container>
      )
    default:
      return (
        <Container maxWidth={"xl"} key={key}>
          <Grid container rowSpacing={4} justifyContent={"center"}>
            <Grid item xs={0} lg={2} xl={2} />
            <Grid item xs={12} lg={8} xl={8}>
              {element}
            </Grid>
            <Grid item xs={0} lg={2} xl={2} />
          </Grid>
        </Container>
      )
  }
}

export function getKirbyBlockElement (b:Block, idx:number, scope?:string) {
  // console.log('getKirbyBlockElement - getKirbyBlockElement: ', b.type, b)
  if (b) {
    switch (b.type) {
      case BlockType.HEADLINE:
        return <KirbyHeading key={`heading-${idx.toString()}`} data={b} scope={scope} />
      case BlockType.TEXT:
        return <KirbyText key={`text-${idx.toString()}`} data={b} scope={scope} />
      case BlockType.ANIMATIONS:
        return <KirbyAnimations key={`animations-${idx.toString()}`} data={b} scope={scope} />
      case BlockType.ANIMATION:
        return <KirbyAnimation key={`animation-${idx.toString()}`} data={b} scope={scope} />
      case BlockType.CARDS:
        return <KirbyCardGroup key={`card-group-${idx.toString()}`} data={b} scope={scope} />
      case BlockType.GALLERY:
        return <KirbyGallery key={`gallery-${idx.toString()}`} data={b} scope={scope} />
      case BlockType.FOOTER:
        return <KirbyFooter key={`footer-${idx.toString()}`} data={b} scope={scope} />
      case BlockType.LIST:
        return <KirbyList key={`list-${idx.toString()}`} data={b} scope={scope} />
      case BlockType.IMAGE:
        return <KirbyImage key={`image-${idx.toString()}`} data={b} scope={scope} />
      case BlockType.PANEL:
        return <KirbyPanel key={`panel-${idx.toString()}`} data={b} scope={scope} getBlockElement={getKirbyBlockElement} />
      case BlockType.ANCHORNAVIGATION:
        return <KirbyAnchorNavigation key='anchor-navigation' data={b} scope={scope} />
      case BlockType.FRAME:
        return <KirbyFrame key={`frame-${idx.toString()}`} data={b} scope={scope} />
      case BlockType.SPACER:
        return <KirbySpacer key={`spacer-${idx.toString()}`} data={b} scope={scope} />
      default:
        return <p key={`default-${idx.toString()}`}>Unknown type: {b.type}</p>
      }
  }
  return null;
}