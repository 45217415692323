import React, {useMemo} from 'react'
import {BlockHeader} from "../../types/Block";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {
  AppBar, IconButton, Typography, Toolbar, Box, Stack, Container
} from "@mui/material";
import {Menu} from "@mui/icons-material";
import {KirbyProps} from "../../types/KirbyProps";
import {Link as RouterLink} from "react-router-dom";
import {Close} from '@mui/icons-material';

interface Props extends KirbyProps {
  open:boolean;
  asOverlay?:boolean;
  handleOpen():void;
  handleClose():void;
  data?:BlockHeader,
  /**
   * Added to <AppBar />
   */
  sx?: SxProps<Theme>;
  /**
   * <Container maxWidth />
   */
  maxWidth: "sm" | "md" | "lg" | "xl" | false;
  /**
   * Added to <Container />
   */
  containerSx?: SxProps<Theme>;
}

export default function AppHeader (props:Props) {

  const toolbarStyle:SxProps = {
    maxHeight: "inherit",
  }

  const containerMaxWidth:"sm" | "md" | "lg" | "xl" | false = useMemo(() => {
    switch(props.maxWidth) {
      case "sm":
      case "md":
      case "lg":
      case "xl":
        return props.maxWidth;
      default:
        return false;
    }
  }, [props.maxWidth]);

  return (
    <AppBar
      position="fixed"
      elevation={props.asOverlay ? 0 : 3}
      sx={{...props.sx}}
    >
      <Container maxWidth={containerMaxWidth} sx={{maxHeight: "inherit", ...props.containerSx}}>
        <Toolbar sx={toolbarStyle} variant={props.asOverlay ? "transparent" : "regular"}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{width: "100%", maxHeight: "inherit"}}
          >
            <Stack direction={"row"} alignSelf={"flex-start"} alignItems={"center"} sx={{maxHeight: "inherit"}}>
              {props.data?.logourl &&
              <Box
                className={"header"}
                component={RouterLink}
                sx={{
                  alignSelf: "flex-start",
                  maxWidth: {xs: "100px", sm: "140px", md: "unset"}
                }}
                to={"/"}
              >
                <img src={props.data?.logourl} alt={props.data?.title} width={"100%"}/>
              </Box>}
              {props.data?.title && !props.asOverlay && <Typography variant="h1" noWrap>
                {props.data?.title}
              </Typography>}
            </Stack>
            {!props.asOverlay &&
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={props.handleOpen}
                edge="end"
                sx={{ margin: "", width: "3rem", height: "3rem" }}
              >
                <Menu />
              </IconButton>
            }
            {props.asOverlay &&
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={props.handleClose}
                edge="end"
                sx={{ margin: "", width: "3rem", height: "3rem" }}
              >
                <Close />
              </IconButton>
            }
          </Stack>
        </Toolbar>
        {props.children}
      </Container>
    </AppBar>
  )
}
