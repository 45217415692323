import React, {createRef, PropsWithChildren, useEffect } from 'react'
import {BlockAnimation} from "../../types/Block";
import {Box} from "@mui/material";

interface Props extends PropsWithChildren<any>{
  data:BlockAnimation,
}

export default function KirbyAnimation (props:Props) {
  const conRef = createRef()

  const path =
    process.env.NODE_ENV === 'development'
      ? `http://localhost/${props.data.path ?? ''}`
      : props.data.path ?? ''

  useEffect(() => {
    return () => {
      
    };
  }, [conRef]);

  const width:string | undefined =
    (props.data.width && !Number.isNaN(props.data.width) && `${props.data.width}px`)
    || (props.data.width && Number.isNaN(props.data.width) && `${props.data.width}`)
    || undefined

  const height:string | undefined =
    (props.data.height && !Number.isNaN(props.data.height) && `${props.data.height}px`)
    || (props.data.height && Number.isNaN(props.data.height) && `${props.data.height}`)
    || undefined


  return (
    <>
      {path &&
      <Box 
        ref={conRef}
        className={"iframe-container"}
        sx={{
          width: "100%",
          height: height ?? "300px",
          border: 0,
          outline: 0,
          overflow: "hidden",
          textAlign: "center",
      }}>
        <Box component={"iframe"}
           sx={{
             width: width ?? "100%",
             height: height ?? "300px",
             overflow: "hidden",
             border: 0,
             outline: 0,
             background: "transparent",
             maxWidth: width ?? "unset",
             margin: "0 auto"
           }}
           src={path}
        />
      </Box>

      }
    </>
  )
}
