import React from 'react';
import clsx from 'clsx';
import {styled, SxProps} from "@mui/system";
import {Theme} from "@emotion/react";
import {Container, Fade, IconButton, Stack} from "@mui/material";
import {ModalUnstyled} from '@mui/base';
import {Close} from '@mui/icons-material';
import {KirbyProps} from "../../types/KirbyProps";
import useNavigation from "../../hooks/useNavigation";
import KirbyNavigationButton from "./KirbyNavigationButton";

interface Props extends KirbyProps {
  open:boolean;
  handleClose():void;
  handleClick?():void;
  modalSx?: SxProps<Theme>;
  containerSx?: SxProps<Theme>;
  containerMaxWidth?:"xs"|"sm"|"md"|"lg"|"xl"|false;
  closeButton?:JSX.Element;
}

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 3rem;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BackdropUnstyled = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
  >((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'MuiBackdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const Backdrop = styled(BackdropUnstyled)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
`;

export default function KirbyNavigationOverlay (props:Props) {
  const {items} = useNavigation();

  function CloseButton ():JSX.Element {
    return (
      <IconButton
        color="inherit"
        aria-label="Navigation schließen"
        onClick={props.handleClose}
        edge="end"
        sx={{
          position: "absolute",
          top: "1rem",
          right: "2rem"
        }}
      >
        <Close />
      </IconButton>
    );
  }

  return (
    <Fade in={props.open}>
      <StyledModal
        aria-labelledby="Hauptnavigation"
        aria-describedby="Hauptnavigation"
        onClose={props.handleClose}
        open
        components={{ Backdrop }}
        sx={props.modalSx}
      >
        <Container maxWidth={props.containerMaxWidth} sx={props.containerSx}>
          {props.children ??
            (items &&
              <Stack dir={"row"}>
                {items?.map(item => {
                  return (
                    <KirbyNavigationButton url={item.url} onClick={props.handleClick} copy={item.copy} key={item.copy} />
                  )
                })}
              </Stack>
            )
          }
          {props.closeButton ?? <CloseButton />}
        </Container>
      </StyledModal>
    </Fade>
  )
}
