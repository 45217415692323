import {useEffect, useState} from 'react';
import {useAppState} from "../context/AppContext";
import {INavItem} from "../types/INavItem";

const useNavigation = (pathname:string = '') => {
  const [items, setItems] = useState<INavItem[]>();
  const [footerItems, setFooterItems] = useState<INavItem[]>();
  const {state, listedSections, sections, navitems, footer} = useAppState()

  useEffect(() => {
    if(state === "LOADED") {
      if(pathname) {
        // sub
        const match = listedSections?.find(s => s.id === 'default' && s.url === pathname)
        if(match && match.id === 'default' && match.sections) {
          let navSections:INavItem[] = []
          match.sections.forEach(s => {
            if(s.id === 'default') {
              navSections.push({
                copy: s.navtitle,
                url: s.url,
                target: '',
              })
            }
          })
          setItems(navSections)
        }
      } else {
        // main
        const navSections:INavItem[] = []
        sections?.forEach(s => {
          if(s.id === 'default' && navitems?.indexOf(s.url) !== -1) {
            navSections.push({
              copy: s.navtitle,
              url: s.url,
              target: '',
            })
          }
        })
        setItems(navSections)
      }

      // footer
      const footerSections:INavItem[] = []
      sections?.forEach(s => {
        if(s.id === 'default' && footer.items?.indexOf(s.url) !== -1) {
          footerSections.push({
            copy: s.navtitle,
            url: s.url,
            target: '',
          })
        }
      })
      setFooterItems(footerSections)
    }
    return () => {
    };
  }, [state, listedSections, sections, pathname, footer, navitems]);

  return {
    items,
    footerItems,
  }
};

export default useNavigation;