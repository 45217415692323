import React, {useMemo} from 'react'
import {BlockFooter} from "../../types/Block";
import useNavigation from "../../hooks/useNavigation";
import {Link as  RouterLink} from "react-router-dom";
import {Box, Container, Divider, Link, Stack, Typography} from "@mui/material";
import {KirbyProps} from "../../types/KirbyProps";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

interface Props extends KirbyProps {
  data:BlockFooter,
}

export default function KirbyFooter (props:Props) {
  const {footerItems} = useNavigation('footer')

  const itemStyle:SxProps = {
    minWidth: {xs: "unset", md: "30%"},
    textAlign: {xs: "center", md: "left"},
    mb: {xs: 1, md: 0},
  }

  const containerMaxWidth:"sm" | "md" | "lg" | "xl" | false = useMemo(() => {
    switch(props.data.maxwidth) {
      case "sm":
      case "md":
      case "lg":
      case "xl":
        return props.data.maxwidth;
      default:
        return false;
    }
  }, [props.data.maxwidth]);

  const containerSx:SxProps<Theme> = useMemo(() => {
    switch(props.data.maxwidth) {
      case "sm":
      case "md":
      case "lg":
      case "xl":
        return {};
      default:
        // a value is passed
        return {maxWidth: props.data.maxwidth};
    }
  }, [props.data.maxwidth]);

  return (
    <Box
      component={"footer"}
      sx={{
        backgroundColor: props.data.background ?? "#fff",
        width: "100%",
        ...props.sx
      }}
      mt={16}
    >
      <Container maxWidth={containerMaxWidth} sx={containerSx}>
        <Stack
          direction={{xs: "column", md: "row"}}
          justifyContent={"space-between"}
          sx={{
            width: "100%",
            padding: {xs: "1rem"}
          }}
        >
          {props.data?.logourl &&
            <>
              <Box
                component={RouterLink}
                sx={{
                  p: {xs: 0},
                  ...itemStyle
                }}
                to={"/"}
              >
                <img src={props.data?.logourl} alt={props.data?.logo} />
              </Box>
              <Divider orientation="vertical" flexItem />
            </>
          }
          {props.data?.text &&
          <>
            <Box
                sx={{
                  p: {xs: 4, sm: 0},
                  ...itemStyle
                }}
              >
                <Typography
                  variant={"subtitle2"}
                  component={"p"}
                  dangerouslySetInnerHTML={{ __html: props.data.text}}
                />
              </Box>
              <Divider variant={"middle"} orientation="vertical" flexItem />
            </>
          }
          {footerItems &&
            <Stack
              direction={{
                xs: "column",
              }}
              sx={{
                pb: {xs: 0},
                height: "100%",
                ...itemStyle
              }}
              justifyContent={"center"}
              alignContent={"start"}
              component={"nav"}
            >
              {footerItems.map((item, idx) => {
                return (
                  <Link variant={"subtitle2"} component={RouterLink} to={item.url} key={`footer-${idx.toString()}`}>{item.copy}</Link>
                )
              })}
            </Stack>
          }
        </Stack>
      </Container>
    </Box>
  )
}
