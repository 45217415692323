import React, {createContext} from "react";
import {IKirbyData} from "../types/IKirbyData";
import {Section} from "../types/Section";
import { QueryClient, QueryClientProvider } from 'react-query';

enum ACTIONS {
  SAVE_DATA = "SAVE_DATA",
}

export enum AppStatus {
  INIT = "INIT",
  LOADING = "LOADING",
  LOADED = "LOADED",
  ERROR = "ERROR",
}

type InitialStateType = {
  state:AppStatus;
  id?:string;
  [propName: string]: any;
  sections?:Section[];
  navitems?:string[];
  metaitems?:string[];
  listedSections?:Section[];
}

type PayloadType = {
  type:ACTIONS;
  payload:any;
}

const initialState = {
  state: AppStatus.INIT,
}

const AppStateContext = createContext<
  InitialStateType
>(
  initialState,
);

const AppDispatchContext = createContext<React.Dispatch<any>
>(
  () => null
);

const saveJson = (json:IKirbyData) => {
  return {
    type: ACTIONS.SAVE_DATA,
    payload: json,
  };
};

function getListedSections (sections:Section[]) {
  const list:Section[] = []
  if(sections && sections.length > 0) {
    sections.forEach(s => {
      const subSections = s.id === 'default' ? getListedSections(s.sections) : []
      // console.log('subSections - : ', s.url, subSections)
      if(s.url) {
        list.push(s)
        if(subSections && subSections.length > 0) {
          subSections.forEach(sub => list.push(sub))
        }
      }
    })
  }
  return list
}

const AppReducer = (state:InitialStateType, action:PayloadType) => {
  switch (action.type) {
    case ACTIONS.SAVE_DATA: {
      const { sections } = action.payload;
      return {
        ...state,
        state: AppStatus.LOADED,
        ...action.payload,
        listedSections: getListedSections(sections),
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(AppReducer, initialState);
  return (
    <QueryClientProvider client={queryClient}>
      <AppStateContext.Provider value={state}>
        <AppDispatchContext.Provider value={dispatch}>
          {children}
        </AppDispatchContext.Provider>
      </AppStateContext.Provider>
    </QueryClientProvider>
  );
}

function useAppState() {
  const context = React.useContext(AppStateContext);
  if (context === undefined) {
    throw new Error("useAppState must be used within a AppProvider");
  }
  return context;
}

function useAppDispatch() {
  const context = React.useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error("useAppDispatch must be used within a AppProvider");
  }
  return context;
}

export { AppProvider, useAppState, useAppDispatch, saveJson };
