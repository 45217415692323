import React, {PropsWithChildren, useMemo } from 'react'
import {BlockAnimation, BlockAnimations} from "../../types/Block";
import {Box, Fade} from "@mui/material";
import KirbyAnimation from "../KirbyAnimation/KirbyAnimation";

interface Props extends PropsWithChildren<any>{
  data:BlockAnimations,
}

interface BreakPoints {
  [key:string]: BlockAnimation | undefined
}

export default function KirbyAnimations (props:Props) {

  const containerHeight = useMemo(() => props.data.height, [props.data.height]);

  function getAnimationForBreakpoint(bp:BlockAnimation|undefined, display:any, width:string){
    if(bp) {
      return (
        <Box
          key={`animation-${bp.breakpoint}`}
          className={`animation-container-${bp.breakpoint}`}
          sx={{
            display,
            m: "auto",
            width,
            height: bp.height ?? "100%"
          }}>
          <KirbyAnimation data={bp as BlockAnimation}/>
        </Box>
      )
    }
    return null
  }

  const animations = useMemo(() => {
    const breakPoints:BreakPoints = {
      xs: undefined,
      md: undefined,
      lg: undefined,
      xl: undefined,
    }

    props.data?.breakpoints?.forEach((ani:BlockAnimation) => {
      if(ani.type === "animation") {
        breakPoints[ani.breakpoint] = ani;
      }
    })

    const w = props.data.width

    const widths = {
      xs: w || (breakPoints.xs?.width && `${breakPoints.xs.width}px`) || '100%',
      sm: w || (breakPoints.sm?.width && `${breakPoints.sm.width}px`) || '100%',
      md: w || (breakPoints.md?.width && `${breakPoints.md.width}px`) || '100%',
      lg: w || (breakPoints.lg?.width && `${breakPoints.lg.width}px`) || '100%',
      xl: w || (breakPoints.xl?.width && `${breakPoints.xl.width}px`) || '100%',
    }

    return ([
      getAnimationForBreakpoint(
        breakPoints?.["xs"],
        {
          xs: "block",
          sm: (breakPoints?.xs && !breakPoints?.sm ? "block" : "none"),
          md: (breakPoints?.xs && !breakPoints?.sm && !breakPoints?.md ? "block" : "none"),
          lg: (breakPoints?.xs && !breakPoints?.sm && !breakPoints?.md && !breakPoints?.lg ? "block" : "none"),
          xl: (breakPoints?.xs && !breakPoints?.sm && !breakPoints?.md && !breakPoints?.lg && !breakPoints?.xl ? "block" : "none"),
        },
        widths["xs"]
      ),
      getAnimationForBreakpoint(
        breakPoints?.["sm"],
        {
          xs: (breakPoints?.xs ? "none" : "block"),
          sm: "block",
          md: (!breakPoints?.md ? "block" : "none"),
          lg: (!breakPoints?.lg ? "block" : "none"),
          xl: (!breakPoints?.xl && !breakPoints?.lg ? "block" : "none"),
        },
        widths["sm"]
      ),
      getAnimationForBreakpoint(
        breakPoints?.["md"],
        {
          xs: (breakPoints?.sm || breakPoints?.xs ? "none" : "block"),
          sm: (breakPoints?.sm || breakPoints?.xs ? "none" : "block"),
          md: "block",
          lg: (!breakPoints?.lg ? "block" : "none"),
          xl: (!breakPoints?.xl && !breakPoints?.lg ? "block" : "none"),
        },
        widths["md"]
      ),
      getAnimationForBreakpoint(
        breakPoints?.["lg"],
        {
          xs: (breakPoints?.md || breakPoints?.sm || breakPoints?.xs ? "none" : "block"),
          sm: (breakPoints?.md || breakPoints?.sm || breakPoints?.xs ? "none" : "block"),
          md: (breakPoints?.md || breakPoints?.sm || breakPoints?.xs ? "none" : "block"),
          lg: "block",
          xl: (!breakPoints?.xl ? "block" : "none"),
        },
        widths["lg"]
      )
    ])
  }, [props.data]);

  return <Fade
    in
    timeout={{
      enter: 800,
      exit: 500,
    }}
  ><Box sx={{
    position: "relative",
    overflow: "hidden",
    backgroundImage: props.data.backgroundimage ? `url(${props.data.backgroundimage})` : "unset",
    backgroundSize: "cover",
    height: containerHeight ?? "unset",
    display: "flex",
    margin: "auto",
  }}>
    {animations}
  </Box></Fade>
}
