import React from 'react'
import {Box, useScrollTrigger, Zoom} from "@mui/material";
import {SxProps, Theme} from "@mui/system";

interface Props {
  target:Node;
  children: React.ReactElement;
  boxSx?:SxProps<Theme>;
}

export default function ScrollTop(props: Props) {
  const { children, target } = props;

  const trigger = useScrollTrigger({
    target: target,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          ...props.boxSx
        }}
      >
        {children}
      </Box>
    </Zoom>
  );
}