import React from 'react'
import { SxProps } from "@mui/system";
import {Theme} from "@emotion/react";
import {Box, Divider, Drawer, List} from "@mui/material";
import {Close} from '@mui/icons-material';
import {KirbyProps} from "../../types/KirbyProps";
import useNavigation from "../../hooks/useNavigation";
import KirbyDrawerItem from "./KirbyDrawerItem";

interface Props extends KirbyProps {
  open?:boolean;
  showCloseButton?:boolean;
  handleDrawerToggle?():void;
  handleClick?():void;
  sx?: SxProps<Theme>;
}

const drawerWidth = 240;

export default function KirbyDrawer (props:Props) {
  const {items, footerItems} = useNavigation()
  return (
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="temporary"
        anchor="top"
        open={props.open}
        onClose={props.handleDrawerToggle}
      >
        {props.showCloseButton && <List>
          <Box component={"li"} key={"closeButton"}>
            <KirbyDrawerItem
              handleClick={props.handleClick}
              endIcon={<Close />}
              sx={{
                background: "transparent",
                width: "fit-content",
                margin: "auto 0 auto auto",
              }}
            />
          </Box>
        </List>}
        {items && <List>
          {items?.map(item => {
            return (
              <li key={item.copy}>
                <KirbyDrawerItem copy={item.copy} to={item.url} handleClick={props.handleClick}/>
              </li>
            )
          })}
        </List>}
        <Box sx={{flexGrow: 1}}/>
        <Divider />
        {footerItems && <List>
          {footerItems?.map((item) => {
            return (
              <li key={item.copy}>
                <KirbyDrawerItem copy={item.copy} to={item.url} handleClick={props.handleClick} />
              </li>
            )
          })}
        </List>}
      </Drawer>
  )
}
