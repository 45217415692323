import React, {Children, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Box, Button, Stack} from "@mui/material";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import {SxProps, Theme} from "@mui/system";
import {useSwipeable} from "react-swipeable";
import { keyframes } from "@emotion/react";

interface Props extends PropsWithChildren<any> {
  slidesSx?:SxProps<Theme>;
  buttonSx?:SxProps<Theme>;
  indicatorSx?: { normal?: SxProps<Theme>, active?: SxProps<Theme> };
  indicatorContainerSx?:SxProps<Theme>;
  hideNavigation?:boolean;
  hideIndicators?:boolean;
  indicatorPos?:"left"|"center"|"right";
  navigationPos?:"spread"|"center"|"top";
  disableInteraction?:boolean;
  duration?:number;
}

function Navigation (props: {
  onNext():void;
  onPrev():void;
  buttonSx?:SxProps<Theme>;
  disabled?:boolean;
  position?:"spread"|"center"|"top";
}):JSX.Element {
  return (
    <Stack
      direction={"row"}
    sx={{
      position: "absolute",
      top: props.position === "center" ? "unset" : 0,
      bottom: props.position === "top" ? "unset" : 0,
      right: 0,
      left: 0,
      width: "100%",
      height: (props.position === "center" || props.position === "top") ? "unset" : "100%",
      justifyContent: (props.position === "center" || props.position === "top") ? "center" : "space-between",
      alignItems: "center",
      pointerEvents: "none",
    }}
    >
      <Button
        onClick={props.onPrev}
        sx={{
          pointerEvents: props.disabled ? "none" : "auto",
          minWidth: "unset",
          height: "3rem",
          width: "3rem",
          ...props.buttonSx,
        }}
      >
        <KeyboardArrowLeft color={"secondary"} />
      </Button>
      <Button
        onClick={props.onNext}
        sx={{
          pointerEvents: props.disabled ? "none" : "auto",
          minWidth: "unset",
          height: "3rem",
          width: "3rem",
          ...props.buttonSx,
        }}
      >
        <KeyboardArrowRight color={"secondary"} />
      </Button>
    </Stack>
  )
}

function Dot (
  props:{
    active?:boolean,
    index: number,
    sx?:SxProps<Theme>,
    handleClick(index:number):void;
    disabled?:boolean;
  }):JSX.Element {
  return (
    <Box
      component={"button"}
      onClick={() => props.handleClick(props.index)}
      sx={{
        backgroundColor: (theme) => props.active ? theme.palette.primary.main : theme.palette.common.white,
        borderColor: (theme) => props.active ? theme.palette.primary.main : "#707070",
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "50%",
        pointerEvents: props.disabled ? "none" : "auto",
        ...props.sx,
      }}
    />
  );
}

function Indicators (props: {
  onIndicatorClick(index:number):void;
  indicatorSx?: { normal?: SxProps<Theme>, active?: SxProps<Theme> };
  count:number;
  currentIndex:number;
  pos?:"left"|"center"|"right";
  disabled?:boolean;
  containerSx?:SxProps<Theme>;
}):JSX.Element | null {
  const indicators = useMemo(() => {
    if (props.count && props.count > 1) {
      const dots = [];
      for (let i=0; i<props.count; i++) {
        const active = (i === props.currentIndex);
        dots.push(
          <Dot
            active={active}
            index={i}
            sx={{
              height: "12px",
              width: "12px",
              maxHeight: "12px",
              maxWidth: "12px",
              padding: 0,
              margin: "4px",
              ...(active ? props.indicatorSx?.active : props.indicatorSx?.normal)
            }}
            handleClick={props.onIndicatorClick}
            disabled={props.disabled}
            key={`dot-${i.toString()}`}
          />
        );
      }
      return dots;
    } else {
      return null;
    }
  }, [props.count, props.disabled, props.currentIndex, props.indicatorSx, props.onIndicatorClick]);

  const posSx = useMemo(() => {
    switch (props.pos) {
      case "left":
        return {
          justifyContent: "flex-start",
        }
      case "right":
        return {
          justifyContent: "flex-end",
        }
      case "center":
      default:
        return {
          justifyContent: "center",
        }
    }
  }, [props.pos]);

  if(Boolean(indicators)) {
    return (
     <Stack
       direction={"row"} alignSelf={"flex-end"}
       sx={{
         position: "absolute",
         bottom: "0.5rem",
         right: "1rem",
         left: "1rem",
         height: "100%",
         display: "flex",
         alignItems: "flex-end",
         pointerEvents: "none",
         ...posSx,
         ...props.containerSx,
       }}
     >{indicators}</Stack>
    );
  }
  return null;
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
    visibility: hidden;
  }
  1% {
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
    visibility: visible;
  }
  99% {
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
`;

export default function KirbyCarousel (props:Props):JSX.Element {
  const [slideIndex, setSlideIndex] = useState(0);
  const [heights, setHeights] = useState<number[]>([]);
  const [height, setHeight] = useState<number>(0);
  const [maxHeight, setMaxHeight] = useState<number>(0);
  const [windowWidth, setWindowWidth] = useState<number>(0);
  const [intervalId, setIntervalId] = useState<number>(-1);

  const itemsRef = useRef<Array<HTMLDivElement | null>>([]);

  const handlersBox = useSwipeable({
    onSwiped: ({ dir, event }) => {
      event.stopPropagation();
    },
    onSwipedLeft: (({ event}) => {
      nextSlide();
    }),
    onSwipedRight: (({ event}) => {
      prevSlide();
    }),
  });

  function onResize():void{
    setWindowWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const _setIntervalId = useCallback(
    (value) => {
      setIntervalId(value);
    },
    [],
  );

  useEffect(() => {
    if (props.duration && props.duration > 0) {
      const int:number = window.setInterval(() => {
        setSlideIndex( slideIndex < slides.length-1 ? (slideIndex+1) : 0);
      }, props.duration);
      _setIntervalId(int);
    }
    return () => {
      if(intervalId !== -1) {
        clearInterval(intervalId);
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [props.duration, slideIndex, _setIntervalId]);


  const slides = useMemo(() => {
    const arrayChildren = Children.toArray(props.children);
    return Children.map(arrayChildren, (child, index) => {
      return (<Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          width: "100%",
          animation: `${(index === slideIndex) ? fadeIn : fadeOut} 400ms ease-in-out`,
          animationFillMode: "forwards",
          animationIterationCount: 1,
          pointerEvents: (index === slideIndex) ? "auto" : "none",
          ...props.slidesSx,
        }}
        ref={el => itemsRef.current[index] = el as HTMLDivElement}
      >
        {child}
      </Box>)
    });
  }, [props.children, props.slidesSx, slideIndex]);

  useEffect(() => {
    let maxHeight:number = 0;
    let hght:number = 0;
    const h:number[] = [];
    itemsRef.current.map((el, idx) => {
      const elementHeight:number = el?.clientHeight ?? 0
      h[idx] = elementHeight;
      if(elementHeight > maxHeight) {
        maxHeight = el?.clientHeight ?? 0;
      }
      hght += elementHeight;
      return elementHeight;
    });
    setHeights(h);
    setMaxHeight(maxHeight);
    setHeight(hght / itemsRef.current.length);
  }, [itemsRef, windowWidth, props.children]);

  function nextSlide():void {
    setSlideIndex( slideIndex < slides.length-1 ? (slideIndex+1) : 0)
  }

  function prevSlide():void {
    setSlideIndex( slideIndex > 0 ? (slideIndex-1) : slides.length-1)
  }

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        top: 0,
        right: 0,
        left: 0,
        display: "flex",
        minHeight: props.navigationPos === "top" ? heights[slideIndex] : maxHeight,
        height: props.navigationPos === "top" ? heights[slideIndex] : maxHeight,
        justifyContent: "center",
        transition: "opacity 0.4s ease-in-out",
      }}
    >
      <Box
        sx={{
          width: "100%",
          position: "relative",
          display: "flex",
          overflow: "hidden",
          minHeight: (props.navigationPos === "top" && heights[slideIndex]) || (props.navigationPos === "spread" && height) || maxHeight,
          height: (props.navigationPos === "top" && heights[slideIndex]) || (props.navigationPos === "spread" && height) || maxHeight,
        }}
        {...handlersBox}
      >
        {slides}
        {!props.hideNavigation && slides && slides.length > 1 &&
          <Navigation
            onNext={nextSlide}
            onPrev={prevSlide}
            buttonSx={props.buttonSx}
            disabled={props.disableInteraction}
            position={props.navigationPos}
          />
        }
      </Box>
      {!props.hideIndicators &&
        <Indicators
          onIndicatorClick={setSlideIndex}
          currentIndex={slideIndex}
          count={slides.length}
          pos={props.indicatorPos}
          disabled={props.disableInteraction}
          containerSx={{
            ...(props.hideNavigation ? {
              left: "1rem",
              right: "1rem",
            } : {}),
            ...props.indicatorContainerSx
          }}
        />
      }
    </Box>
  );
}