import { useEffect } from 'react';
import {useQuery} from "react-query";
import {saveJson, useAppDispatch} from "src/context/AppContext";

const useKirbyData = (path:string, key:string = 'kirbyData') => {
    const appDispatch = useAppDispatch()
    const { isLoading, error, data } = useQuery(key, () =>
      fetch(path).then(res =>
        res.json()
      )
    )
    useEffect(() => {
        if(!isLoading && !error && data) {
            appDispatch(saveJson(data))
        }
    }, [isLoading, error, data]);

    return {
        isLoading,
        error,
        data
    }
};

export default useKirbyData;