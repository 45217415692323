import React, {PropsWithChildren, ReactNode} from 'react'
import { SxProps } from "@mui/system";
import {Theme} from "@emotion/react";
import {ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {Link as RouterLink, To, useMatch, useResolvedPath} from "react-router-dom";

interface Props extends PropsWithChildren<any>{
  copy?:string;
  to?:To;
  handleClick?():void;
  sx?: SxProps<Theme>;
  endIcon?:ReactNode,
}

export default function KirbyDrawerItem (props:Props) {
  const resolved = useResolvedPath(props.to ?? "")
  // console.log('default - KirbyDrawerItem: ', resolved.pathname, props.to)
  const match = useMatch({path: resolved?.pathname, end: true})

  return (
    <ListItemButton
      component={props.to ? RouterLink : "button"} to={props.to ?? ""}
      selected={!!match}
      sx={{
        width: "100%",
        justifyContent: "spaceBetween",
        ...props.sx
      }}
      onClick={props.handleClick}
    >
      <ListItemText primary={props.copy}/>
      {props.endIcon && <ListItemIcon sx={{minWidth: 0}}>{props.endIcon}</ListItemIcon>}
    </ListItemButton>
  )
}
