import React, { PropsWithChildren, useEffect, useRef, useState} from 'react'
import { BlockGallery} from "../../types/Block";
import {Fade, ImageList, ImageListItem} from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import {IKirbyImage} from "src/types/IKirbyImage";

interface Props extends PropsWithChildren<any>{
  data:BlockGallery,
}

export default function KirbyGallery (props:Props) {
  const xs = useMediaQuery('(min-width:0)');
  const md = useMediaQuery('(min-width:900px)');
  const lg = useMediaQuery('(min-width:1200px)');

  const [columns, setColumns] = useState(props.data.columnsXs ?? 1);
  const [rowHeight, setRowHeight] = useState(props.data.rowheight ?? 0);
  const [height, setHeight] = useState(0);

  const ref = useRef<HTMLUListElement>(null);

  const onResize = () => {
    if(ref && ref.current) {
      const cols =
        (lg && (props.data.columnsLg ?? 4))
        || (md && (props.data.columnsMd ?? 3))
        || (xs && (props.data.columnsXs ?? 2))
        || 2
      const w = ref.current.offsetWidth / cols;

      setColumns(cols);
      if(!props.data.rowheight) {
        // Square bei false oder 0
        setHeight(
          Math.ceil(props.data.images.length / cols) * w
        );
        setRowHeight(w);
      } else {
        setHeight(
          Math.ceil(props.data.images.length / cols) * rowHeight
        );
      }
    }
  }

  useEffect(() => {
    if(ref && ref.current) {
      onResize();
      window.addEventListener('resize', onResize);
    }
    return () => {};
  });

  return <Fade
    in
    timeout={{
      enter: 800,
      exit: 500,
    }}
  >
    <ImageList
      ref={ref}
      sx={{
        width: "100%",
        height: `${height}px`,
        overflow: "hidden",
      }}
      cols={columns}
      rowHeight={rowHeight}
    >
      {props.data.images && props.data.images.map((image:IKirbyImage) => {
        return (
          <ImageListItem key={image.name}>
            <img
              src={`${image.url}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${image.url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={image.name}
              loading="lazy"
            />
          </ImageListItem>
        )

      })}
    </ImageList>
  </Fade>
}
