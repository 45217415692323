import React from 'react'
import {Button} from "@mui/material";
import {ButtonPropsVariantOverrides, ButtonTypeMap} from "@mui/material/Button/Button";
import {ExtendButtonBase} from "@mui/material/ButtonBase";
import {KirbyProps} from "../../types/KirbyProps";
import { OverridableStringUnion } from '@mui/types';
import {BlockButton} from "src/types/Block";

interface Props extends KirbyProps {
  data:BlockButton;
  muiProps?:ExtendButtonBase<ButtonTypeMap>;
  variant?: OverridableStringUnion<
    'text' | 'outlined' | 'contained',
    ButtonPropsVariantOverrides
    >;
}

export default function KirbyButton (props:Props) {
  return <Button
    variant={props.variant ?? "contained"}
    sx={props.sx}
    {...(props.className && {className: props.className})}
    {...(props.id && {id: props.id})}
  >
    {props.data.text}
  </Button>
}
