import React from 'react'
import {BlockSnackbar} from "../../types/Block";
import {KirbyProps} from "../../types/KirbyProps";
import {IconButton, Snackbar} from "@mui/material";
import {Close} from '@mui/icons-material';
import {SnackbarProps} from "@mui/material/Snackbar/Snackbar";

interface Props extends KirbyProps {
  data:BlockSnackbar;
  handleClose():void;
  open:boolean;
  autoHideDuration?:number | null;
  muiProps?:SnackbarProps;
}

export default function KirbySnackbar (props:Props) {
  const action = (
    <>
      {/*<Button color="secondary" size="small" onClick={props.handleClose}>
        UNDO
      </Button>*/}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={props.handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </>
  );

  return <div>
    <Snackbar
      open={props.open}
      autoHideDuration={props.autoHideDuration}
      onClose={props.handleClose}
      message={props.data.text}
      action={action}
      {...props.muiProps}
    />
  </div>
}
