import React, {ReactElement, useEffect, useState} from 'react'
import {BlockAnchorNavigation} from "../../types/Block";
import {useAppState} from "../../context/AppContext";
import {IKirbySection} from "../../types/IKirbySection";
import {KirbyProps} from "../../types/KirbyProps";
import {Button} from "@mui/material";

interface Props extends KirbyProps{
  data:BlockAnchorNavigation,
}

export default function KirbyAnchorNavigation (props:Props) {
  const {listedSections} = useAppState()
  const [elements, setElements] = useState<ReactElement[]>([]);

  const onClick = (page:IKirbySection) => {
    let element = document.getElementById(page.url);
    if(element) element.scrollIntoView({behavior: "smooth"});
  }

  useEffect(() => {
    let elem:ReactElement[] = []
    if(props.data && props.data.items && listedSections) {
      props.data.items.forEach(item => {
        const page = listedSections.find(s => s.url === item)
        if(item && page) {
          elem.push(
            <Button
              key={item}
              variant={props.variant ?? "contained"}
              sx={{margin: "0 0.5rem"}}
              onClick={() => onClick(page)}
            >{page.navtitle}</Button>
          )
        }
      })
    }
    setElements(elem)
    return () => {}
  }, [listedSections, props.data, props.variant])

  return <div className='anchor-navigation'>
    {elements}
  </div>
}

