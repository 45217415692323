import React from 'react'
import {BlockText} from "../../types/Block";
import {KirbyProps} from "../../types/KirbyProps";
import {Typography, TypographyPropsVariantOverrides} from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { OverridableStringUnion } from '@mui/types';

interface Props extends KirbyProps {
  data:BlockText,
  variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
}

export default function KirbyText (props:Props) {
  return <Typography
    component={'p'}
    variant={props.variant ?? "body1"}
    sx={props.sx}
    dangerouslySetInnerHTML={{ __html: props.data.text}}
    {...(props.className && {className: props.className})}
    {...(props.id && {id: props.id})}
  />;
}
