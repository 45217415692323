import React from 'react'
import {BlockList} from "../../types/Block";
import {KirbyProps} from "../../types/KirbyProps";
import {Box} from "@mui/material";

interface Props extends KirbyProps{
  data:BlockList;
}

export default function KirbyList (props:Props) {
  return <Box dangerouslySetInnerHTML={{ __html: props.data.text}} sx={{
    ul: {
      listStyle: "none",
      margin: 0,
      padding: 0,
    }
  }}>
    {props.children}
  </Box>
}
