import React, { ReactNode} from 'react'
import {BlockCard, BlockCards} from "../../types/Block";
import KirbyCard from "../KirbyCard/KirbyCard";
import {Box, Grid} from "@mui/material";
import {KirbyProps} from "../../types/KirbyProps";

interface Props extends KirbyProps {
  data:BlockCards,
  getCard?(data:BlockCard):JSX.Element | null,
}


export default function KirbyCardGroup (props:Props) {

  const getColumn = (key:string, layout:string, card:JSX.Element | null) => {
    switch (layout) {
      case '1_4':
        return (
          <Grid key={key} item xs={12} sm={6} md={4} lg={3}>{card}</Grid>
        )
      case '1_3':
        return (
          <Grid key={key} item xs={12} sm={6} lg={4}>{card}</Grid>
        )
      case '1_2':
        return (
          <Grid key={key} item xs={12} md={6}>{card}</Grid>
        )
      case '1_1':
      default:
        return (
          <Grid key={key} item xs={12} md={12}>{card}</Grid>
        )
    }
  }

  return (
    <Box sx={{flexGrow: 1, m: 4, ...props.sx}}>
      <Grid container spacing={4}>
        {props.data.cards && props.data.cards.map((card, idx) => {
          return getColumn(
            `card-${idx.toString()}`,
            props.data.layout,
              props.getCard ? props.getCard(card) : <KirbyCard data={card}/>
          )
        })}
      </Grid>
    </Box>
  )
}

