import React, {useEffect, useMemo} from 'react'
import {Box} from "@mui/material";
import {BlockFrame} from "../../types/Block";
import {getResponsiveValues} from "../../utils/utils";
import {SxProps, Theme} from "@mui/system";
import {KirbyProps} from "../../types/KirbyProps";

interface Props extends KirbyProps {
  data:BlockFrame,
  sx?:SxProps<Theme>,
  scope?: string | undefined,
}

export default function KirbyFrame (props:Props) {
  const {style, src, mapsid, height} = props.data;

  const path = useMemo(() => {
    if(style === "url" && src) {
      return src;
    }
  }, [style, src]);

  useEffect(() => {
    if(style !== "url") {
      // Reload iframe manager
      // @ts-ignore
      if (window.InitializeIFrameManager) window.InitializeIFrameManager();
    }
  }, [style]);

  return (
    <Box sx={{
      id: props.data?.id,
      height: height ? getResponsiveValues(height) : "320px",
      background: "rgba(0,0,0,0.02)"
    }}>
      {style === "url" && path &&
        <iframe
          title={"frame"}
          width="100%"
          height="100%"
          style={{border: 0}}
          loading="lazy"
          allowFullScreen
          src={path}
        ></iframe>
      }
      {style === "maps" && mapsid &&
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
        data-service="google_maps"
        data-id={decodeURI(mapsid)}
        // data-autoscale
        data-iframe-id="googleMapsEmbed"
        data-iframe-loading="lazy"
        data-iframe-frameborder="0"
      />
      }
    </Box>
  )
}

