import React from 'react'
import {BlockHeadline} from "../../types/Block";
import {KirbyProps} from "../../types/KirbyProps";
import {Typography, TypographyPropsVariantOverrides} from "@mui/material";
import {OverridableStringUnion} from "@mui/types";
import {Variant} from "@mui/material/styles/createTypography";

interface Props extends KirbyProps {
  data:BlockHeadline,
  variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
}

export default function KirbyHeading (props:Props) {
  return <Typography
    variant={props.level ?? props.data.level}
    sx={props.sx}
    style={{wordWrap: "break-word"}}
    id={props.id}
    {...(props.className && {className: props.className})}
    dangerouslySetInnerHTML={{ __html: props.data.text}}
  />
}
