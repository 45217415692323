import {ResponsiveImage} from "./ResponsiveImage";
import {IKirbyImage} from "./IKirbyImage";

export enum BlockType {
    HEADER = 'header',
    HEADLINE = "headline",
    TEXT = 'text',
    LINK = 'link',
    BUTTON = 'button',
    LIST = 'list',
    IMAGE = 'image',
    SLIDER = 'slider',
    STAGE = 'stage',
    QUOTE = 'quote',
    SNACKBAR = 'snackbar',
    CARDS = 'cards',
    CARD = 'card',
    INFOCARD = 'infocard',
    GALLERY = 'gallery',
    FRAME = 'frame',
    ANIMATIONS = 'animations',
    ANIMATION = 'animation',
    PAGECONTAINER = 'pagecontainer',
    ANCHORNAVIGATION = 'anchornavigation',
    PAGE = 'page',
    FORM = 'form',
    FOOTER = 'footer',
    PANEL = 'panel',
    SPACER = 'spacer',
}

export enum Align {
    left='left',
    center='center',
    right='right',
    justify='justify',
}

export interface ResponsiveSx {
    xs: string
    sm?: string
    md?: string
    lg?: string
    xl?: string
    unit?: string
}

export interface KirbyBlock {
    id?:string
    css?:{[key: string]: ResponsiveSx}
}

export type ImagePosition = {
    breakpoint: {
        size: "xs" | "sm" | "md" | "lg" | "xl",
        vertical: "top" | "center" | "bottom",
        horizontal: "left" | "center" | "right",
    }
}

export type Breakpoints = "xs" | "sm" | "md" | "lg" | "xl";

export interface ImageSize {
    [key: string]:string,
}

export type ImageRatio = {
    breakpoint: {
        size: Breakpoints,
        height?: number,
        ratio: "1_1" |"1_2" |"4_3" |"fixed",
        unit?: "px" | "pc" | "vh"| "rem",
    }
}

export interface BlockImageExt extends BlockImage {
    sizes?:Record<Breakpoints, string>
    ratios?:ImageRatio[]
    position?:ImagePosition[]
}

export interface BlockHeader extends KirbyBlock {
    type: BlockType.HEADER
    title:string
    logo?:string
    logourl?:string
    background?:string
}

export interface BlockHeadline extends KirbyBlock {
    type: BlockType.HEADLINE
    text:string
    level?: "h1" | "h2" | "h3" | "h4" | "h5"| "h6" | "body1" | "body2" | "subtitle1" | "subtitle2" | "inherit"
    maxwidth?:ResponsiveSx
    variant?:string
    align?:Align
}

export interface BlockText extends KirbyBlock {
    type: BlockType.TEXT
    text:string
    maxwidth?:ResponsiveSx
    variant?:string
    align?:Align
}

export interface BlockButton extends KirbyBlock {
    type: BlockType.BUTTON
    text:string
    variant?:string
    anchor?:string
    to?:string
    href?:string
    mailto?:string
    align?:Align
}

export interface BlockLink extends KirbyBlock {
    type: BlockType.LINK
    text:string
    variant?:string
    anchor?:string
    to:string
    href?:string
    mailto?:string
    align?:Align
}

export interface BlockList extends KirbyBlock {
    type: BlockType.LIST
    text:string
}

export interface BlockSnackbar extends KirbyBlock {
    type: BlockType.SNACKBAR
    text:string
}

export interface BlockImage extends KirbyBlock {
    type: BlockType.IMAGE
    alt?:string
    height?:ResponsiveSx
    minheight?:ResponsiveSx
    maxheight?:ResponsiveSx
    align?:Align
    ratio?:string
    size?:"cover"|"contain"|undefined
    src?:string
    srcxs?:string
    responsiveimage?:ResponsiveImage
}

export interface BlockSlider extends KirbyBlock {
    type: BlockType.SLIDER
    heading?:BlockHeadline
    text?:BlockText
    footer?:Block[]
    image?:IKirbyImage
    responsiveimage?:ResponsiveImage
    duration?:number
    height?: ResponsiveSx
    minheight?: ResponsiveSx
    maxheight?: ResponsiveSx
    slides?:Block[]
    style?:string
}

export interface BlockStage extends KirbyBlock {
    type: BlockType.STAGE
    align?:Align
    image?:IKirbyImage
    responsiveimage?:ResponsiveImage
    blocks?:Block[]
    height?:ResponsiveSx
    minheight?:ResponsiveSx
    maxheight?:ResponsiveSx
}

export interface BlockSpacer extends KirbyBlock {
    type: BlockType.SPACER
    scope?:string
    height: {xs:number, sm?:number, md?:number, lg?:number, xl?:number, unit: "px"|"pc"|"vh"|"rem"}
    minheight: {xs:number, sm?:number, md?:number, lg?:number, xl?:number, unit: "px"|"pc"|"vh"|"rem"}
    maxheight: {xs:number, sm?:number, md?:number, lg?:number, xl?:number, unit: "px"|"pc"|"vh"|"rem"}
}

export interface BlockPanel extends KirbyBlock {
    type: BlockType.PANEL
    variant?:string;
    align?:Align | string
    blocks:Block[]
}

export interface BlockCards extends KirbyBlock {
    type: BlockType.CARDS
    layout:string
    style?:string
    filter?:string
    cards?:BlockCard[]
}

export interface BlockCard extends KirbyBlock {
    type: BlockType.CARD
    heading:string
    subtitle?:string
    copy?:string
    align?:Align
    cta?:string
    action?:string
    name?:string
    url:string
    tags?:string,
}

export interface BlockInfoCard extends KirbyBlock {
    type: BlockType.INFOCARD
    background?:Block
    variant?:string;
    align?:Align | string
    blocks:Block[]
}

export interface BlockGallery extends KirbyBlock {
    type: BlockType.GALLERY
    images:IKirbyImage[]
    rowheight:number
    columnsXs:number
    columnsMd:number
    columnsLg:number
}

export interface BlockFrame extends KirbyBlock {
    type: BlockType.FRAME
    height?: ResponsiveSx
    style:"url"|"maps"
    src?: string;
    mapsid?: string;
}

export interface BlockAnimations extends KirbyBlock {
    type: BlockType.ANIMATIONS
    title:string
    backgroundimage:string
    width: string
    height: string
    breakpoints: BlockAnimation[]
}

export interface BlockAnimation extends KirbyBlock {
    type: BlockType.ANIMATION
    path:string
    breakpoint: "xs" | "sm" | "md" | "lg" | "xl"
    width:string
    height:string
}

export interface BlockPageContainer extends KirbyBlock {
    type: BlockType.PAGECONTAINER
    page:string
}

export interface BlockAnchorNavigation extends KirbyBlock {
    type: BlockType.ANCHORNAVIGATION
    items:string[]
}

export interface BlockForm extends KirbyBlock {
    type: BlockType.FORM
    heading:string
    cta:string
    action:string
    infos:BlockText[] | BlockHeadline[]
}

export interface BlockFooter extends KirbyBlock {
    type: BlockType.FOOTER
    text:string
    logo:string
    logourl:string
    background:string
    items:string[]
    maxwidth?: "sm" | "md" | "lg" | "xl" | string;
}

export type Block =
    | BlockAnimations
    | BlockAnimation
    | BlockHeader
    | BlockHeadline
    | BlockInfoCard
    | BlockText
    | BlockButton
    | BlockLink
    | BlockList
    | BlockImage
    | BlockGallery
    | BlockFrame
    | BlockCards
    | BlockCard
    | BlockSlider
    | BlockStage
    | BlockSnackbar
    | BlockPageContainer
    | BlockAnchorNavigation
    | BlockForm
    | BlockFooter
    | BlockSpacer
    | BlockPanel
