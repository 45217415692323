import React, {PropsWithChildren, useEffect, useRef} from 'react'
import {BlockCard} from "../../types/Block";
import {Button, Card, CardActions, CardContent, CardMedia, Stack, Typography} from "@mui/material";
import {Link} from "react-router-dom";

interface Props extends PropsWithChildren<any>{
  data:BlockCard,
}

export default function KirbyCard (props:Props) {
  const cardWidth = "30%";
  const minHeight = 160;

  const ref = useRef<HTMLDivElement>(null);

  const onResize = () => {
    if(ref && ref.current) {
      // const w = ref.current.offsetWidth
      // console.log('default - onResize: ', w)
    }
  }

  useEffect(() => {
    if(ref && ref.current) {
      onResize();
      window.addEventListener('resize', onResize);
    }
    return () => {};
  }, [ref]);


  return (
    <Card sx={{
      maxWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: {
        xs: 'column',
        md: 'row',
      },
      ...props.sx
    }}>
      {props.data.url && props.data.name && <CardMedia
        sx={{
          width: {xs: "100%", md: cardWidth},
          maxWidth: {xs: "100%", md: cardWidth},
          minWidth: {xs: "100%", md: cardWidth},
          minHeight: `${minHeight}px`,
          height: `100%`,
          // maxHeight: {xs: "240px", md: `${maxHeight}px`},
        }}
        ref={ref}
        image={props.data.url}
        title={props.data.name}
      />}
      <Stack sx={{flexGrow: 1}} direction={{xs: "column", md: "column"}}>
        <CardContent sx={{flexGrow: 1, textAlign: props.data.align ?? "left"}}>
          {props.data.heading &&
            <Typography className={"card-heading"} gutterBottom variant="h5" component="h3" dangerouslySetInnerHTML={{ __html: props.data.heading}} />
          }
          {props.data.subtitle &&
          <Typography className={"card-subtitle"} gutterBottom variant="h6" component="h4" dangerouslySetInnerHTML={{ __html: props.data.subtitle}} />
          }
          {props.data.copy &&
            <Typography className={"card-copy"} gutterBottom component="p" dangerouslySetInnerHTML={{ __html: props.data.copy}} />
          }
        </CardContent>
        {props.data.action && props.data.cta &&
          <CardActions sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: {xs: "flex-end", md: "center", },
            alignItems:
              (props.data.align === "justify" && "flex-start")
              || (props.data.align === "center" && "center")
              || (props.data.align === "right" && "flex-end")
              || "flex-start"
            ,
            flexGrow: 1,
            pointerEvents: 'auto',
            textAlign: props.data.align ?? "left",
          }}>
            <Button
              component={Link}
              sx={{m: 2}}
              variant="contained"
              color="secondary"
              to={`/${props.data.action}`}
            >
              {props.data.cta}
            </Button>
          </CardActions>
        }
      </Stack>
    </Card>
  )
}
